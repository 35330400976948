import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, NavigationExtras, Router, RouterStateSnapshot } from '@angular/router';
import { OAuthService, JwksValidationHandler } from 'angular-oauth2-oidc';
import { authConfig } from '@shared/models/authConfig';
import { AuthManager } from './auth-manager';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private oauthService: OAuthService, private router: Router, private authManager: AuthManager) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> | boolean {
    // this.authManager.resetRetryLogin();
    if (this.authManager.isMaxRetryLoginReached()) {
      const navigationExtras: NavigationExtras = { queryParams: { 'isAccessDenied': true } };
      this.router.navigate(['/login'], navigationExtras);
      return false;
    }
    this.authManager.increaseRetryLoginCount();

    this.oauthService.configure(authConfig);
    this.oauthService.tokenValidationHandler = new JwksValidationHandler();

    return this.oauthService.loadDiscoveryDocumentAndLogin()
    .then((res) => {
          return this.oauthService.hasValidIdToken() && this.oauthService.hasValidAccessToken();
    });
  }
}
