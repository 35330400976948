import { Component } from '@angular/core';

@Component({
  selector: 'app-protected',
  templateUrl: './protected.component.html',
  styleUrls: ['./protected.component.scss']
})
export class ProtectedComponent {
  title = 'ds-admin-site';
  hasReplacementFunction = true;
  constructor() { }
}
