import { Component, OnInit, Input } from '@angular/core';
import { ComponentResult } from './models/component.result';
import { ComponentSerialNumberService } from '@shared/services/component-serial-number.service';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-component-serial-number',
  templateUrl: './component-serial-number.component.html',
  styleUrls: ['./component-serial-number.component.scss']
})
export class ComponentSerialNumberComponent implements OnInit {
  meetLengthRequired: boolean;
  maxSerialLength: number;
  @Input() searchField: string;

  //searchResults: ComponentResult[] = [];
  componentDetails: ComponentResult;
  componentData: MatTableDataSource<any>;

  displayedColumns: string[] = ['serialNumber', 'partNumber', 'componentDescription'];
  
  constructor(private serialSearchService: ComponentSerialNumberService) {
  }

  ngOnInit(): void {
    this.search();
  }

  search() {
    if (this.searchField && this.searchField.trim().length > 0) {
      this.serialSearchService.searchBySerialNumber(this.searchField.trim())
        .subscribe(
          (data: ComponentResult) => {
            this.componentDetails = data;
            this.componentData = new MatTableDataSource<any>(this.componentDetails.ComponentModels);

            //this.searchResults = [this.componentDetails];
          },
          (error) => {
            console.error('Error occurred during search:', error);
          }
        );
    }
  }
}
