import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { OAuthService, JwksValidationHandler } from 'angular-oauth2-oidc';
import { authConfig } from '@shared/models/authConfig';
import { UserInfoService } from '@shared/services/user-info.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HeaderComponent implements OnInit {
  isAuthenticated: boolean;
  givenName: string;

  constructor(private oauthService: OAuthService,
    private userInfoService: UserInfoService) {

    this.oauthService.configure(authConfig);
    this.oauthService.tokenValidationHandler = new JwksValidationHandler();
    this.oauthService.loadDiscoveryDocumentAndLogin();
  }

  ngOnInit() {
    // Get the authentication state for immediate use
    this.isAuthenticated = this.oauthService.hasValidAccessToken();
    this.givenName = this.userInfoService.givenName;
  }

  login() {
    this.oauthService.initImplicitFlow();
  }

  logout() {
    // Terminates the session with Okta and removes current tokens. 
    this.oauthService.logOut(false);
  }
}
