import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@environment/environment';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Access-Control-Allow-Credentials': 'true',
    'Access-Control-Allow-Methods': 'POST, OPTIONS',
    'X-DeviceId': navigator.userAgent
  })
};

@Injectable({
  providedIn: 'root'
})

export class UnitReplacementService {

  constructor(private http: HttpClient) { }

  replaceDefectUnit(originalUnitSerialNumber: string, replacementSerialNumber: string, replacementSkuNumber: string) {
    const baseUrl = environment.AzureFunctionUrl;
    const url = `${baseUrl}/api/FullUnitReplacementNew`;
    const body = {
      DefectiveUnitSerialNumber: originalUnitSerialNumber,
      ReplacementSerialNumber: replacementSerialNumber,
      ReplacementSkuNumber: replacementSkuNumber
     };
   return this.http.post(url, body, httpOptions);
  }
}
