import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-content-display-horizontal',
  templateUrl: './content-display-horizontal.component.html',
  styleUrls: ['./content-display-horizontal.component.scss']
})
export class ContentDisplayHorizontalComponent {
  @Input() label: string;
  @Input() divider: boolean;
}
