import { RegistrationService } from '@shared/services/registration.service';
import { Component, OnInit, Input, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SkaSearchService } from '@shared/services/ska-search.service';
import { Activity } from '@shared/models/activity';
import { MatDialogRef } from '@angular/material/dialog';
import _groupBy from 'lodash.groupby';
import { getDateOnly } from '@shared/helpers/date-format';


@Component({
  selector: 'app-view-activity',
  templateUrl: './view-activity.component.html',
  styleUrls: ['./view-activity.component.scss']
})
export class ViewActivityComponent implements OnInit {
  activities: Activity[];
  errorMessage:string;
  groupedActivities: any[][];
  serialNumber: string;
  noResultsShow: boolean;
  ShowLoader: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private skaSearchService: SkaSearchService,
    public dialogRef: MatDialogRef<ViewActivityComponent>,
    public registrationService: RegistrationService
  ) {
    this.serialNumber = data.searchField;
  }

  ngOnInit() {
    this.ShowLoader = false;
    this.getActivities();
  }

  getActivities() {
    this.ShowLoader = true;
    if (this.serialNumber !== undefined && this.serialNumber !== null) {
      this.skaSearchService.search(this.serialNumber).subscribe(result => {
        if (result && result.activities) {
          this.ShowLoader = false;
          this.activities = result.activities;
          const grp = _groupBy(this.activities, (activity: Activity) => {
            const activityTimeStamp = new Date(activity.ts);
            return activityTimeStamp && getDateOnly(activityTimeStamp)
          },);
          
          const grp2 = Object.keys(grp).map((key) => [String(key), grp[key]]);
          const grp2sorted = grp2.sort((a,b) => b[0] - a[0]);
          this.groupedActivities = grp2sorted;
        } else {
          this.activities = [];
          this.groupedActivities = [];
          this.ShowLoader = false;
        }
      },
      error =>{
        this.activities = [];
        this.groupedActivities = [];
        this.ShowLoader = false;
        this.errorMessage=error.error.message;        
      });
    } else {
      this.activities = [];
      this.groupedActivities = [];
      this.ShowLoader = false;
    }
  }
}
