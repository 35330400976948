import { Component, Input } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
    selector: 'app-chevron',
    templateUrl: './chevron.component.html',
    styleUrls: ['./chevron.component.scss'],
    animations: [
        trigger('openClose', [
            // ...
            state('open', style({
                background: 'url(../../../../assets/images/icons/menu/Icon_UserMenu-Close.svg) no-repeat center',
                width: 'auto'
            })),
            state('closed', style({
                background: 'url(../../../../assets/images/icons/menu/Icon_UserMenu-Open.svg) no-repeat'
            })),
            transition('open <=> closed', [
              animate('0.5s ease-in-out', style('*'))
            ]),
          ]),
    ]
  })
export class ChevronComponent {
    @Input() menuOpen: boolean;
    @Input() inlineSpacing: boolean;

    constructor() {}
}
