import { Component, OnInit, Input } from '@angular/core';
import { UnitReplacementModel } from './models/unit.replacement.result';
import { UnitReplacementService } from '@shared/services/unit-replacement-details.service';

@Component({
  selector: 'app-unit-replacement-description',
  templateUrl: './unit-replacement-description.component.html',
  styleUrls: ['./unit-replacement-description.component.scss']
})
export class UnitReplacementDescriptionComponent implements OnInit {
  @Input() serialNumber: string;
  replacementDetails: UnitReplacementModel;

  displayedColumns: string[] = ['replacementDate', 'replaceUnitSN', 'replaceUnitSKU', 'replacedBySN', 'replacedBySKU'];
  dataSource: UnitReplacementModel[] = [];

  constructor(private replacementService: UnitReplacementService) { }

  ngOnInit(): void {
    this.getReplacementDetails();
  }

  getReplacementDetails() {
    if (this.serialNumber && this.serialNumber.trim().length > 0) {
      this.replacementService.getUnitReplacementDetails(this.serialNumber.trim())
        .subscribe(
          (data: UnitReplacementModel) => {
            this.replacementDetails = data;
            this.dataSource = [this.replacementDetails]; // Wrap replacementDetails in an array
          },
          (error) => {
            console.error('Error occurred while fetching replacement details:', error);
          }
        );
    }
  }
}
