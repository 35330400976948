import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from '@environment/environment';

export const authConfig: AuthConfig = {
    issuer: environment.oktaConfig.issuer,
    redirectUri: environment.oktaConfig.redirectUri,
    clientId: environment.oktaConfig.clientId,
    logoutUrl: environment.oktaConfig.logoutRedirectUri,
    postLogoutRedirectUri: environment.oktaConfig.logoutRedirectUri,
    scope: 'openid profile groups-AmwayDurables'
  };
