export class AuthManager {
    retryKey = 'retry_login';
    maxRetry = 2;

    resetRetryLogin() {
        sessionStorage.setItem(this.retryKey, '0');
    }

    isMaxRetryLoginReached(): Boolean {
        const retryLogin = parseInt(sessionStorage.getItem(this.retryKey));
        if (retryLogin >= this.maxRetry) {
            return true;
        }

        return false;
    }

    increaseRetryLoginCount() {
        let retryLogin = parseInt(sessionStorage.getItem(this.retryKey));
        retryLogin++;
        sessionStorage.setItem(this.retryKey, retryLogin.toString())
    }
}   