import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@environment/environment';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Access-Control-Allow-Credentials': 'true',
    'Access-Control-Allow-Methods': 'POST, OPTIONS',
    'X-DeviceId': navigator.userAgent
  })
};  

@Injectable({
  providedIn: 'root'
})
export class ComponentReplacementService {

  constructor(private http: HttpClient) { }

  replace(unitSerialNumberField: string, componentSerialNumberField: string, 
    defectiveComponentSerialNumberField:string,reasonForReplacementField: string) {
    const url = `${environment.AzureFunctionUrl}/api/ComponentReplacementNew`;
    const body = {
      unitSerialNumber: unitSerialNumberField!=null?unitSerialNumberField.trim():'',
      componentSerialNumber: componentSerialNumberField!=null?componentSerialNumberField.trim():'',
      defectiveComponentSerialNumber:defectiveComponentSerialNumberField!=null?defectiveComponentSerialNumberField.trim():"",
      reasonForReplacement: reasonForReplacementField || 'Created via Durables Admin website'
     };

    return this.http.post(url, body, httpOptions);
  }
}
