import { UnitReplacementService } from '@shared/services/unit-replacement.service';

import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {  FormGroup, FormControl, Validators, NgForm } from '@angular/forms';
import { environment } from '@environment/environment';

@Component({
  selector: 'app-unit-replacement',
  templateUrl: './unit-replacement.component.html',
  styleUrls: ['./unit-replacement.component.scss']
})
export class UnitReplacementComponent implements OnInit {
  @ViewChild('formDirective', {static: false}) private formDirective: NgForm;

  componentForm: FormGroup;
  defectiveUnitSerialNumber: FormControl;
  replacementSerialNumber: FormControl;
  replacementSkuNumber: FormControl;
  errorMessage: string;
  maxSerialLength: number;
  minSerialLength: number;
  minSkuLength: number;

  constructor(public snackBar: MatSnackBar,
    private unitReplacementService: UnitReplacementService) {
      this.maxSerialLength = environment.maxSerialNumberLength;
      this.minSerialLength = environment.minSerialNumberLength;
      this.minSkuLength = environment.minSkuNumberLength;
    }

    ngOnInit() {
      this.createFormControls();
      this.createForm();
    }

    createForm() {
      this.componentForm = new FormGroup({
        defectiveUnitSerialNumber: this.defectiveUnitSerialNumber,
        replacementSerialNumber: this.replacementSerialNumber,
        replacementSkuNumber: this.replacementSkuNumber
      });
    }

    createFormControls() {
      this.defectiveUnitSerialNumber = new FormControl('', [
        Validators.required,
        Validators.minLength(this.minSerialLength )
      ]);
      this.replacementSerialNumber = new FormControl('', [
        Validators.required,
        Validators.minLength(this.minSerialLength )
      ]);
      this.replacementSkuNumber = new FormControl('', [
        Validators.required,
        Validators.minLength(this.minSkuLength )
      ]);
    }

  onSubmit() {
    if (this.componentForm.valid) {
      this.errorMessage = '';
      if (this.defectiveUnitSerialNumber && this.replacementSerialNumber && this.replacementSkuNumber) {
        this.unitReplacementService.replaceDefectUnit(this.defectiveUnitSerialNumber.value, this.replacementSerialNumber.value, this.replacementSkuNumber.value)
          .subscribe( () => {
              this.resetForm();
              this.errorMessage = '';
              this.snackBar.open('Unit Replacement has been Completed Successfully.', null, {
                verticalPosition: 'top',
                duration: 4000,
                panelClass: ['component-save-snackbar']
              });
            }, error => {
              // tslint:disable-next-line:max-line-length
              const DEFAULT_ERROR_MESSAGE = 'Oops, something went wrong processing your request. Please try again or contact your system administrator.';
              this.errorMessage = DEFAULT_ERROR_MESSAGE;
              if (error.status === 403) {
                this.errorMessage = 'You need permission to do this action. Please contact your system administrator';
              } else if (error) {
                this.errorMessage = error.error;
              }
            });
      }
    }
  }

  getErrorMessageForUnit() {
    this.errorMessage = '';
    if (this.defectiveUnitSerialNumber.hasError('required') ) {
      return 'Defective Unit Serial Number is required.';
    } else if (this.defectiveUnitSerialNumber.hasError('minlength')) {
      return 'Minimum length is ' + this.minSerialLength ;
    }
    return '';
  }

  getErrorMessageForReplacementUnit() {
    this.errorMessage = '';
    if (this.replacementSerialNumber.hasError('required') ) {
      return 'Replacement Unit Serial Number is required.';
    } else if (this.replacementSerialNumber.hasError('minlength')) {
      return 'Minimum length is ' + this.minSerialLength ;
    }
  }

  getErrorMessageForReplacementUnitSku() {
    this.errorMessage = '';
    if (this.replacementSkuNumber.hasError('required') ) {
      return 'Replacement Unit SKU Number is required.';
    } else if (this.replacementSkuNumber.hasError('minlength')) {
      return 'Minimum length is ' + this.minSkuLength ;
    }
  }

  resetForm() {
    this.componentForm.markAsPristine();
    this.formDirective.resetForm();
    if (this.componentForm) {
      this.componentForm.reset();
    }
  }
}
