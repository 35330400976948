export const componentTypeJson = [
    {Description: 'Adapter'},
    {Description: 'Dust Sensor'},
    {Description: 'EM'},
    {Description: 'EM unit'},
    {Description: 'Filter Housing'},
    {Description: 'Front Shield'},
    {Description: 'Handle'},
    {Description: 'Motor'},
    {Description: 'Power Cord'},
    {Description: 'Rear Shell'},
    {Description: 'Shield'},
    {Description: 'Volute'}
  ];