import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { SkaResult } from '@shared/models/ska-result';
import { environment } from '@environment/environment';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Access-Control-Allow-Credentials': 'true',
    'Access-Control-Allow-Origin': 'https://amway-durablesservices-dev.crm.dynamics.com',
    'Access-Control-Allow-Methods': 'GET, OPTIONS',
    'X-DeviceId': navigator.userAgent
  })
};

@Injectable({
  providedIn: 'root'
})
export class SkaSearchService {
  constructor(private http: HttpClient) { }

  search(serialNumber: string): Observable<SkaResult> {
    const baseUrl = environment.AzureFunctionUrl;
    const url = `${baseUrl}/api/SKAQueryNew?serialNumber=${serialNumber}`;

    return this.http.get<SkaResult>(url, httpOptions);
  }
}
