import { Component, OnInit, ViewChild } from '@angular/core';
import { ComponentReplacementService } from '@shared/services/component-replacement.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import {  FormGroup, FormControl, Validators, NgForm } from '@angular/forms';
import { componentTypeJson } from '@assets/config/component-type';
import { environment } from '@environment/environment';

interface ComponentType {
  Description: string;
}

@Component({
  selector: 'app-component-replacement',
  templateUrl: './component-replacement.component.html',
  styleUrls: ['./component-replacement.component.scss']
})
export class ComponentReplacementComponent implements OnInit {
  @ViewChild('formDirective', {static: false}) private formDirective: NgForm;

  componentForm: FormGroup;
  unitSerialNumber: FormControl;
  componentSerialNumber: FormControl;
  defectiveComponentSerialNumber: FormControl;
  reasonForReplacement: FormControl;
  errorMessage: string;
  maxSerialLength: number;
  minSerialLength: number;
  componentMinSerialLength: number;
 
  componentTypes: ComponentType[] = componentTypeJson;

  constructor(public snackBar: MatSnackBar,
    private componentReplacementService: ComponentReplacementService) {
      this.maxSerialLength = environment.maxSerialNumberLength;
      this.minSerialLength = environment.minSerialNumberLength;
      this.componentMinSerialLength = environment.componentMinSerialLength;
  }

    ngOnInit() {
      this.createFormControls();
      this.createForm();
    }

    createFormControls() {
      this.unitSerialNumber = new FormControl('', [
        Validators.required,
        Validators.minLength(this.minSerialLength)
      ]);
      this.componentSerialNumber = new FormControl('', [
        Validators.required,
        Validators.minLength(this.componentMinSerialLength)
      ]);
      this.defectiveComponentSerialNumber = new FormControl('', [
        Validators.minLength(this.componentMinSerialLength)
      ]);
      this.reasonForReplacement = new FormControl('', []);
    }

    createForm() {
      this.componentForm = new FormGroup({
          unitSerialNumber: this.unitSerialNumber,
          componentSerialNumber: this.componentSerialNumber,
          defectiveComponentSerialNumber:this.defectiveComponentSerialNumber,
          reasonForReplacement: this.reasonForReplacement,
      });
    }

  onSubmit() {
    if (this.componentForm.valid) {
      this.errorMessage = '';
      if (this.unitSerialNumber && this.componentSerialNumber) {
        this.componentReplacementService.replace(this.unitSerialNumber.value, this.componentSerialNumber.value,
          this.defectiveComponentSerialNumber.value,this.reasonForReplacement.value)
          .subscribe( () => {
              this.resetForm();
              this.errorMessage = '';
              this.snackBar.open('Component record was replaced.', null, {
                verticalPosition: 'top',
                duration: 4000,
                panelClass: ['component-save-snackbar']
              });
            }, error => {
              // tslint:disable-next-line:max-line-length
              const DEFAULT_ERROR_MESSAGE = 'Oops, something went wrong processing your request. Please check again or contact your system administrator.';
            
              this.errorMessage = DEFAULT_ERROR_MESSAGE;

              if (error.status === 403) {
                this.errorMessage = 'You need permission to do this action. Please contact your system administrator';
              } else if (error) {
                this.errorMessage = error.error;
              }
            });
      }
    }
  }

  getErrorMessageForUnit() {

    if (this.unitSerialNumber.hasError('required') ) {
      return 'Unit Serial Number is required.';
    } else if (this.unitSerialNumber.hasError('minlength')) {
      return 'Minimum length is ' + this.minSerialLength ;
    }

    return '';
  }

  getErrorMessageForNewComponent() {

    if (this.componentSerialNumber.hasError('required') ) {
      return 'Component Unit Serial Number is required.';
    } else if (this.componentSerialNumber.hasError('minlength')) {
      return 'Minimum length is ' + this.componentMinSerialLength;
    }

    return '';
  }

  getErrorMessageForDefectiveComponent() {

    if (this.defectiveComponentSerialNumber.hasError('minlength')) {
      return 'Minimum length is ' + this.componentMinSerialLength;
    }

    return '';
  }

  resetForm() {
    this.componentForm.markAsPristine();
    this.formDirective.resetForm();
    if (this.componentForm) {
      this.componentForm.reset();
    }
  }
}
