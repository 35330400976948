import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ComponentResult } from 'src/app/pages/protected/components/component-serial-number/models/component.result';
import { environment } from '@environment/environment';
import { getHttpHeaders } from './headers.service';

@Injectable({
  providedIn: 'root'
})
export class ComponentSerialNumberService {

  constructor(private http: HttpClient) { }

  searchBySerialNumber(serialNumber: string): Observable<ComponentResult> {
    const baseUrl = environment.AzureFunctionUrl;
    const url = `${baseUrl}/api/GetDurableComponentsFunction`;

    const params = new HttpParams().set('SerialNumber', serialNumber);

    const headers = getHttpHeaders();

    return this.http.get<ComponentResult>(url, { params, headers });
  }
}



