import { Directive, ElementRef, HostListener, Output, EventEmitter, Input } from '@angular/core';

@Directive({
  selector: '[appMinLengthRequired]'
})
export class MinLengthRequiredDirective {
  @Output() meetLengthRequired = new EventEmitter();

  constructor(
    private el: ElementRef) { }

  @HostListener('ngModelChange', ['$event']) ngModelChange(event: string) {
    this.checkLength(this.el.nativeElement.value);
  }

  private checkLength(value) {
    this.meetLengthRequired.emit(value.length >= 3);
  }
}
