import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthManager } from 'src/app/auth/auth-manager';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public isAccessDenied = false;

  constructor(private route: ActivatedRoute, private router: Router, private authManager: AuthManager) { }

  ngOnInit() {
    this.route.queryParams.subscribe((queryParams: any) => {
      if (queryParams['isAccessDenied']) {
        try {
          this.isAccessDenied = Boolean(queryParams['isAccessDenied']);
        }
        catch {}
      }
    });
  }

  login() {
    this.authManager.resetRetryLogin();

    this.router.navigate(['/main']);
  }

}
