import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ISearchResult, SearchResult } from 'src/app/pages/protected/components/search/models/search.result';
import { map } from 'rxjs/operators';
import { environment } from '@environment/environment';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Access-Control-Allow-Credentials': 'true',
    'Access-Control-Allow-Methods': 'GET, OPTIONS'
  })
};

@Injectable({
  providedIn: 'root'
})
export class SerialSearchService {

  constructor(private http: HttpClient) { }

  search(searchString: string): Observable<ISearchResult[]> {
    const searchStringFormatted = searchString.trim();
    const baseUrl = environment.AzureFunctionUrl;
    const params = new HttpParams()
    .set('SerialNumber', searchStringFormatted);

    const url = `${baseUrl}/api/SearchProductBySerialNumber`;

   return this.http.get<ISearchResult[]>(url, {...httpOptions, params}).pipe(
     map((res: ISearchResult[]) => {
       return res;
    })
   );
  }
}

