import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { environment } from '@environment/environment';
import { OAuthModule } from 'angular-oauth2-oidc';

import { LoaderService } from '@shared/services/loader.service';
import { MaterialModule } from '@shared/material.module';
import { FormsModule, ReactiveFormsModule } from '../../node_modules/@angular/forms';
import { SearchComponent } from './pages/protected/components/search/search.component';
import { ComponentReplacementComponent } from './pages/protected/components/component-replacement/component-replacement.component';
import { ViewActivityComponent } from './pages/protected/components/view-activity/view-activity.component';
import {
  ContentDisplayHorizontalComponent
} from './pages/protected/components/search/content-display-horizontal/content-display-horizontal.component';
import { UnitReplacementComponent } from './pages/protected/components/unit-replacement/unit-replacement.component';
import { ProtectedComponent } from './pages/protected/protected.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { AppRoutingModule } from './app-routing.module';
import { HeaderComponent } from './header/header.component';
import { AppComponent } from './app.component';
import { FooterComponent } from './footer/footer.component';
import { AuthGuard } from './auth/auth.guard';
import { LoaderComponent } from '@shared/components/loader/loader.component';
import { LoaderInterceptor } from './interceptor/loader.interceptor';
import { ChevronComponent } from '@shared/components/chevron/chevron.component';
import { LoginComponent } from './pages/login/login.component';
import { MainLayoutComponent } from './layout/main-layout/main-layout.component';
import { LandingLayoutComponent } from './layout/landing-layout/landing-layout.component';
import { MinLengthRequiredDirective } from '@shared/directives/MinLengthRequired/min-length-required.directive';
import { ComponentSerialNumberComponent } from './pages/protected/components/component-serial-number/component-serial-number.component';
import { UnitReplacementDescriptionComponent } from './pages/protected/components/component-unit-replacement-description/unit-replacement-description.component';
import { AuthManager } from './auth/auth-manager';
@NgModule({
  declarations: [
    ProtectedComponent,
    SearchComponent,
    ComponentReplacementComponent,
    ViewActivityComponent,
    ContentDisplayHorizontalComponent,
    UnitReplacementComponent,
    PageNotFoundComponent,
    HeaderComponent,
    AppComponent,
    FooterComponent,
    LoaderComponent,
    ChevronComponent,
    LoginComponent,
    MainLayoutComponent,
    LandingLayoutComponent,
    MinLengthRequiredDirective,
    ComponentSerialNumberComponent,
    UnitReplacementDescriptionComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MaterialModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatTableModule,
    MatSortModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    OAuthModule.forRoot({
      resourceServer: {
          allowedUrls: [`${environment.AzureFunctionUrl}/api`],
          sendAccessToken: true
      }
    }),
    AppRoutingModule
  ],
  providers: [
    AuthGuard,
    HttpClientModule,
    LoaderService,
    AuthManager,
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
  entryComponents: [ViewActivityComponent]
})
export class AppModule { }
