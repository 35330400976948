import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable  } from 'rxjs';
import { RegistrationResult } from '../../pages/protected/components/search/models/registration.results';
import { map } from 'rxjs/operators';
import { environment } from '@environment/environment';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Access-Control-Allow-Credentials': 'true',
    'Access-Control-Allow-Methods': 'GET, OPTIONS'
  })
};

@Injectable({
  providedIn: 'root'
})
export class RegistrationService {
  constructor(private http: HttpClient) {}

  getContactDetailsFromRegistrationId(registrationId: string): Observable<RegistrationResult> {
    const registrationResult = new RegistrationResult();

    if (!!registrationId.trim()) {
      const baseUrl = environment.AzureFunctionUrl;
      const params = new HttpParams()
      .set('RegistrationId', registrationId);

      const url = `${baseUrl}/api/GetRegistrationDetails`;

    return this.http.get<RegistrationResult>(url, {...httpOptions, params}).pipe(
      map((res: RegistrationResult) => {
        return res;
      })
    );
    }

    return null;
  }
}
