import { Component, OnInit, ViewChild } from '@angular/core';
import {
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import { SerialSearchService } from '@shared/services/serial-search.service';
import { environment } from '@environment/environment';
import { ISearchResult } from './models/search.result';
import { User } from '@shared/models/user';
import { BusinessUnit } from '@shared/models/business.unit';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ViewActivityComponent } from '../view-activity/view-activity.component';
import { RegistrationService } from '@shared/services/registration.service';
import { RegistrationResult } from './models/registration.results';

export interface ProductAttributesType {
  ProductName: string;
  SerialNumber: string;
  ProductSku: string;
  RelatedBusinessUnitName: string;
  DateOfShipment: Date;
  RegistrationDate: Date;
  StateCode: string;
}

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({ height: '0px', minHeight: '0', display: 'none' })),
      state('expanded', style({ height: '*' })),
      transition('expanded => collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      transition('expanded => void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ]),
    trigger('chevronToggle', [
      state('collapsed, void', style({ transform: 'rotate(0)' })),
      state('expanded', style({ transform: 'rotate(-180deg)' })),
      transition('expanded <=> collapsed', animate('550ms ease-out')),
      transition('expanded <=> void', animate('550ms ease-in'))
    ])
  ]
})
export class SearchComponent implements OnInit {
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  meetLengthRequired: boolean;
  loading = false;
  isPageOnInit = true;
  maxSerialLength: number;
  hasSearchResults: boolean;
  searchField: string;
  currentUser: User;
  currentUserBusinessUnit: BusinessUnit;
  dataSource: MatTableDataSource<ProductAttributesType>;
  expandedElement: ProductAttributesType;
  businessUnitIds: string;
  columnsToDisplay = [
    'SerialNumber',
    'DisplayName',
    'ProductSku',
    'RelatedBusinessUnitName',
    'DateOfShipment',
    'RegistrationDate',
    'StateCode',
    'extraField'
  ];

  productAttributes = [
    { columnDef: 'SerialNumber', header: 'Serial Number' },
    { columnDef: 'DisplayName',  header: 'Product Name' },
    { columnDef: 'ProductSku',  header: 'SKU' },
    { columnDef: 'RelatedBusinessUnitName',  header: 'Market' },
    { columnDef: 'DateOfShipment',  header: 'Ship Date', icon: 'icon_shipped' },
    { columnDef: 'RegistrationDate',  header: 'Registration Date', icon: 'icon_registered' },
    { columnDef: 'StateCode', header: 'Status' },
    { columnDef: 'extraField' }
  ];

  constructor(
    private serialSearchService: SerialSearchService,
    public dialog: MatDialog,
    public registrationService: RegistrationService
  ) {
    this.dataSource = new MatTableDataSource();
    this.maxSerialLength = environment.maxSerialNumberLength;
  }

  ngOnInit() {}

  showNoResultsPanel(): boolean {
    return (!this.isPageOnInit && !this.loading && !this.hasSearchResults);
  }

  showViewSkaButton(): boolean {
    return ((this.isPageOnInit || !this.hasSearchResults) && !this.loading);
  }

  rowClicked(element: ISearchResult) {
    const elementData = element;
    if (element.RegistrationDate && element.RegistrationId) {
      this.registrationService.getContactDetailsFromRegistrationId(element.RegistrationId)
      .subscribe((contactData: RegistrationResult) => {
        if (contactData) {
          for ( const [key, value] of Object.entries(contactData)) {
            elementData[key] = value;
          }
        }
        return false;
      });
    }

    return false;
  }

  search() {
    if (this.searchField) {
      this.loading = true;
      this.hasSearchResults = false;
      this.isPageOnInit = false;

      this.serialSearchService
        .search(this.searchField)
        .subscribe((data: ISearchResult[]) => {
          const hasData = data && data.length > 0;

          this.loading = false;
          if (hasData) {
            this.hasSearchResults = hasData;
            this.dataSource.data = data;
            this.dataSource.sort = this.sort;
          }
        }, (err) => {
          this.loading = false;
          this.hasSearchResults = false;
          this.isPageOnInit = false;
        });
    }
  }

  openViewEventLogPopup(SNo) {
    const dialogConfig = {
      data: { searchField: SNo },
      width: '80%',
      position: { top: '2rem' }
    };

    const dialogRef = this.dialog.open(ViewActivityComponent, dialogConfig);
  }

  setIsMeetLengthRequired(val) {
    this.meetLengthRequired = val;
  }
}
